import swal from 'sweetalert'

window.emailForm = function (o) {
  function confirmRemove () {
    return swal({
      title: o.i18n.confirmRemoveTitle,
      icon: '/static/base/img/icons/error.svg',
      text: o.i18n.confirmRemoveMessage,
      buttons: {
        cancel: {
          text: o.i18n.confirmRemoveCancel,
          visible: true,
          className: 'ui-button ui-button--secondary'
        },
        confirm: {
          text: o.i18n.confirmRemoveOk,
          className: 'ui-button ui-button--danger'
        }
      }
    })
  }

  function postAction (email, action) {
    const emailField = document.querySelector('#email-action-form input[name=email]')
    emailField.value = email
    const submitBtn = document.querySelector('#email-action-form input[name=action_' + action + ']')
    submitBtn.click()
  }

  function hookupRemoveEmail (buttonElt) {
    function remove () {
      buttonElt.removeEventListener('click', clickListener)
      postAction(buttonElt.dataset.email, 'remove')
    }

    var clickListener = function (e) {
      e.preventDefault()
      confirmRemove().then(function (confirmed) {
        if (confirmed) {
          remove()
        }
      })
    }

    buttonElt.addEventListener('click', clickListener)
  }

  function hookupVerifyEmail (buttonElt) {
    function verify () {
      buttonElt.removeEventListener('click', clickListener)
      postAction(buttonElt.dataset.email, 'send')
    }

    var clickListener = function (e) {
      e.preventDefault()
      verify()
    }

    buttonElt.addEventListener('click', clickListener)
  }

  function hookupSwitchPrimary (radio) {
    radio.addEventListener('change', function (e) {
      e.preventDefault()
      e.target.form.submit()
    })
  }

  function init () {
    const removeButtons = document.querySelectorAll('button.remove-email')
    for (let i = 0; i < removeButtons.length; i++) {
      hookupRemoveEmail(removeButtons[i])
    }
    const verifyButtons = document.querySelectorAll('button.verify-email')
    for (let i = 0; i < verifyButtons.length; i++) {
      hookupVerifyEmail(verifyButtons[i])
    }
    const primaryRadios = document.querySelectorAll('input.primary-email')
    for (let i = 0; i < primaryRadios.length; i++) {
      hookupSwitchPrimary(primaryRadios[i])
    }
  }

  init()
}
