/* Fetch the segment tracking ID asynchronously, and invoke the callback with
   ID, if any. Code intentionally written to be defensive, to make sure JS won't
   crash in case ad blockers are doing their job. */
function requestSegmentId (cb) {
  if (!window.analytics || !window.analytics.ready) {
    cb(null)
    return
  }
  window.analytics.ready(function () {
    let ajs_aid = null
    const user = window.analytics.user()
    if (user && user.anonymousId) {
      ajs_aid = user.anonymousId()
    }
    cb(ajs_aid)
  })
}

/* Fetch the Google analytics client ID, and invoke the callback with the ID, if
   any. Code intentionally written to be defensive, to make sure JS won't crash
   in case ad blockers are doing their job. */
function requestGoogleAnalyticsId (cb) {
  if (!window.ga) {
    cb(null)
    return
  }
  window.ga(function (tracker) {
    let clientId = null
    if (tracker) {
      /* tracker.get('linkerParam') comes in the format:
      _ga=2.31305146.440283611.1650635413-1978058612.1650635413
      we are interested only in the value
      */
      clientId = tracker.get('linkerParam').split("=", 2)[1]
    }
    cb(clientId)
  })
}

/* The backend has middleware up and running to pick up the analytics params
   from any endpoint. Therefore, we don't need a new endpoint to communicate the
   params, and we can simply do fetch call to an existing one. */
function sendIds (params) {
  // Ancient browsers don't do fetch.
  if (!window.fetch || !window.URLSearchParams) return
  fetch('/?' + new URLSearchParams(params))
}

/* Collect Segment/GA IDs and report them back to the backend */
function generateAndSendIds (analyticsParams) {
  requestSegmentId(function (segmentId) {
    requestGoogleAnalyticsId(function (gaId) {
      let changed = false
      if (segmentId && analyticsParams.ajs_aid !== segmentId) {
        analyticsParams.ajs_aid = segmentId
        changed = true
      }
      if (gaId && analyticsParams._ga !== gaId) {
        analyticsParams._ga = gaId
        changed = true
      }
      if (changed) {
        sendIds(analyticsParams)
      }
    })
  })
}

window.generateAndSendIds = generateAndSendIds
