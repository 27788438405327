import './twofactor.scss'

window.twoFactorRecoveryForm = function () {
  const codesArea = document.getElementById('recovery-codes')
  const copyButton = document.getElementById('copy-recovery-codes')
  copyButton.addEventListener('click', function () {
    codesArea.focus()
    codesArea.select()
    document.execCommand('copy')
  })
}
